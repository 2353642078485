import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));
const About = lazy(() => import("../views/About.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));
const Chagas1 = lazy(() => import("../views/ui/Chagas1"));
const Chagas2 = lazy(() => import("../views/ui/Chagas2"));
const Chagas3 = lazy(() => import("../views/ui/Chagas3"));
const Chagas4 = lazy(() => import("../views/ui/Chagas4"));
const Chagas5 = lazy(() => import("../views/ui/Chagas5"));
const Chagas6 = lazy(() => import("../views/ui/Chagas6"));
const Calazar1 = lazy(() => import("../views/ui/Calazar1"));
const Calazar2 = lazy(() => import("../views/ui/Calazar2"));
const Calazar3 = lazy(() => import("../views/ui/Calazar3"));
const Calazar4 = lazy(() => import("../views/ui/Calazar4"));
const Calazar5 = lazy(() => import("../views/ui/Calazar5"));
const Asis = lazy(() => import("../views/ui/Asis"));

const Hans01 = lazy(() => import("../views/ui/hanseniase01"));
const Hans02 = lazy(() => import("../views/ui/hanseniase02"));
const Hans03 = lazy(() => import("../views/ui/hanseniase03"));
const Hans04 = lazy(() => import("../views/ui/hanseniase04"));
const Hans05 = lazy(() => import("../views/ui/hanseniase05"));
const Hans06 = lazy(() => import("../views/ui/hanseniase06"));
const Hans07 = lazy(() => import("../views/ui/hanseniase07"));
const Hans08 = lazy(() => import("../views/ui/hanseniase08"));
const Hans09 = lazy(() => import("../views/ui/hanseniase09"));
const Hans10 = lazy(() => import("../views/ui/hanseniase10"));
const Hans11 = lazy(() => import("../views/ui/hanseniase11"));
const Hans12 = lazy(() => import("../views/ui/hanseniase12"));
const Hans13 = lazy(() => import("../views/ui/hanseniase13"));
const Hans14 = lazy(() => import("../views/ui/hanseniase14"));
const Hans15 = lazy(() => import("../views/ui/hanseniase15"));
const Hans16 = lazy(() => import("../views/ui/hanseniase16"));
const Hans17 = lazy(() => import("../views/ui/hanseniase17"));
const Hans18 = lazy(() => import("../views/ui/hanseniase18"));
const Hans19 = lazy(() => import("../views/ui/hanseniase19"));
const Hans20 = lazy(() => import("../views/ui/hanseniase20"));
const Hans21 = lazy(() => import("../views/ui/hanseniase21"));
const Hans22 = lazy(() => import("../views/ui/hanseniase22"));
const Hans23 = lazy(() => import("../views/ui/hanseniase23"));
const Hans24 = lazy(() => import("../views/ui/hanseniase24"));
const Hans25 = lazy(() => import("../views/ui/hanseniase25"));
const Hans26 = lazy(() => import("../views/ui/hanseniase26"));
const Hans27 = lazy(() => import("../views/ui/hanseniase27"));
const Hans28 = lazy(() => import("../views/ui/hanseniase28"));
const Hans29 = lazy(() => import("../views/ui/hanseniase29"));
const Hans30 = lazy(() => import("../views/ui/hanseniase30"));
const Hans31 = lazy(() => import("../views/ui/hanseniase31"));
const Hans32 = lazy(() => import("../views/ui/hanseniase32"));
const Hans33 = lazy(() => import("../views/ui/hanseniase33"));
const Hans34 = lazy(() => import("../views/ui/hanseniase34"));

const HansG01 = lazy(() => import("../views/ui/hanseniase01_G"));

const Turbe01 = lazy(() => import("../views/ui/tuberculose01"));

const TurbeG01 = lazy(() => import("../views/ui/turberculose01_G.js"));


/* const Hans0 = lazy(() => import("../views/ui/hanseniase0"));
const Hans1 = lazy(() => import("../views/ui/hanseniase1"));
const Hans2 = lazy(() => import("../views/ui/hanseniase2"));
const Hans3 = lazy(() => import("../views/ui/hanseniase3"));
const Hans5 = lazy(() => import("../views/ui/hanseniase5")); */

const Leish1 = lazy(() => import("../views/ui/Leish1"));
const Leish2 = lazy(() => import("../views/ui/Leish2"));
const Leish3 = lazy(() => import("../views/ui/Leish3"));
const Turb1 = lazy(() => import("../views/ui/Turb1"));
const Turb2 = lazy(() => import("../views/ui/Turb2"));
const Turb3 = lazy(() => import("../views/ui/Turb3"));
const IDTNP = lazy(() => import("../views/ui/IDTNP"));
const Transito = lazy(() => import("../views/ui/Transito"));
const HansChart = lazy(() => import("../views/ui/HansChart.js"));
const Mapas = lazy(() => import("../views/ui/Mapas.js"));
const Boletins = lazy(() => import("../views/ui/Boletins.js"));
const BTurb = lazy(() => import("../views/ui/b_turb.js"));
const BTrans = lazy(() => import("../views/ui/b_trans.js"));


/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/starter" /> },
      { path: "/starter", exact: true, element: <Starter /> },
      { path: "/about", exact: true, element: <About /> },
      { path: "/alerts", exact: true, element: <Alerts /> },
      { path: "/badges", exact: true, element: <Badges /> },
      { path: "/buttons", exact: true, element: <Buttons /> },
      { path: "/cards", exact: true, element: <Cards /> },
      { path: "/grid", exact: true, element: <Grid /> },
      { path: "/table", exact: true, element: <Tables /> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/chagas1", exact: true, element: <Chagas1 /> },
      { path: "/chagas2", exact: true, element: <Chagas2 /> },
      { path: "/chagas3", exact: true, element: <Chagas3 /> },
      { path: "/chagas4", exact: true, element: <Chagas4 /> },
      { path: "/chagas5", exact: true, element: <Chagas5 /> },
      { path: "/chagas6", exact: true, element: <Chagas6 /> },
      { path: "/calazar1", exact: true, element: <Calazar1 /> },
      { path: "/calazar2", exact: true, element: <Calazar2 /> },
      { path: "/calazar3", exact: true, element: <Calazar3 /> },
      { path: "/calazar4", exact: true, element: <Calazar4 /> },
      { path: "/calazar5", exact: true, element: <Calazar5 /> },
      { path: "/asis", exact: true, element: <Asis /> },

      { path: "/hans01", exact: true, element: <Hans01 /> },
      { path: "/hans02", exact: true, element: <Hans02 /> },
      { path: "/hans03", exact: true, element: <Hans03 /> },
      { path: "/hans04", exact: true, element: <Hans04 /> },
      { path: "/hans05", exact: true, element: <Hans05 /> },
      { path: "/hans06", exact: true, element: <Hans06 /> },
      { path: "/hans07", exact: true, element: <Hans07 /> },
      { path: "/hans08", exact: true, element: <Hans08 /> },
      { path: "/hans09", exact: true, element: <Hans09 /> },
      { path: "/hans10", exact: true, element: <Hans10 /> },
      { path: "/hans11", exact: true, element: <Hans11 /> },
      { path: "/hans12", exact: true, element: <Hans12 /> },
      { path: "/hans13", exact: true, element: <Hans13 /> },
      { path: "/hans14", exact: true, element: <Hans14 /> },
      { path: "/hans15", exact: true, element: <Hans15 /> },
      { path: "/hans16", exact: true, element: <Hans16 /> },
      { path: "/hans17", exact: true, element: <Hans17 /> },
      { path: "/hans18", exact: true, element: <Hans18 /> },
      { path: "/hans19", exact: true, element: <Hans19 /> },
      { path: "/hans20", exact: true, element: <Hans20 /> },
      { path: "/hans21", exact: true, element: <Hans21 /> },
      { path: "/hans22", exact: true, element: <Hans22 /> },
      { path: "/hans23", exact: true, element: <Hans23 /> },
      { path: "/hans24", exact: true, element: <Hans24 /> },
      { path: "/hans25", exact: true, element: <Hans25 /> },
      { path: "/hans26", exact: true, element: <Hans26 /> },
      { path: "/hans27", exact: true, element: <Hans27 /> },
      { path: "/hans28", exact: true, element: <Hans28 /> },
      { path: "/hans29", exact: true, element: <Hans29 /> },
      { path: "/hans30", exact: true, element: <Hans30 /> },
      { path: "/hans31", exact: true, element: <Hans31 /> },
      { path: "/hans32", exact: true, element: <Hans32 /> },
      { path: "/hans33", exact: true, element: <Hans33 /> },
      { path: "/hans34", exact: true, element: <Hans34 /> },

      { path: "/hans01_G", exact: true, element: <HansG01 /> },

      /* { path: "/hans0", exact: true, element: <Hans0 /> },
      { path: "/hans1", exact: true, element: <Hans1 /> },
      { path: "/hans2", exact: true, element: <Hans2 /> },
      { path: "/hans3", exact: true, element: <Hans3 /> },
      { path: "/hans5", exact: true, element: <Hans5 /> }, */

      { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },
      { path: "/leish1", exact: true, element: <Leish1 /> },
      { path: "/Leish2", exact: true, element: <Leish2 /> },
      { path: "/Leish3", exact: true, element: <Leish3 /> },

      { path: "/Turberculose01", exact: true, element: <Turbe01 /> },
      { path: "/TurbeG01", exact: true, element: <TurbeG01 /> },

      { path: "/Turb1", exact: true, element: <Turb1 /> },
      { path: "/Turb2", exact: true, element: <Turb2 /> },
      { path: "/Turb3", exact: true, element: <Turb3 /> },
      { path: "/idtnp", exact: true, element: <IDTNP /> },
      { path: "/transito", exact: true, element: <Transito /> },
      { path: "/hanschart", exact: true, element: <HansChart /> },
      { path: "/mapas", exact: true, element: <Mapas /> },
      { path: "/boletins", exact: true, element: <Boletins /> },
      { path: "/b_turb", exact: true, element: <BTurb /> },
      { path: "/b_trans", exact: true, element: <BTrans /> },
    ],
  },
];

export default ThemeRoutes;
